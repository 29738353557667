import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Checkbox } from "antd";
import { InputNumber, Input, TextArea } from "components/common/Forms/Input";
import { DatePicker } from "components/common/Forms/DatePicker";
import MainLayout from "../layout/MainLayout";
import { Select } from "components/common/Forms/Select";
import {
  offerTypeOptions,
  contractPeriod,
  evaluationPeriod,
  billingPeriodOngoingOptions,
  billingPeriodIndividualOptions,
  paymentTerm,
  renderEnumOptions,
} from "../../utils/EnumHelpers";
import { changeOfferBudgetLabel } from "../../utils/offerObject";
import * as offersApi from "../../contracts/offer.api";
import { OfferType, OfferBase } from "../../contracts/offer";
import { listServices } from "../../contracts/tenant/tenant.api";
import moment from "moment";
import { FormItem } from "components/common/Forms/FormItem";
import classes from "./styles.module.css";

interface OfferFormProps {
  title: string;
  id?: number;
}

const OfferForm = ({ id, title }: OfferFormProps) => {
  const [form] = Form.useForm<OfferBase>();
  const history = useHistory();
  const [currentChosenOfferType, setCurrentChosenOfferType] = useState<OfferType>(OfferType.Ongoing);
  const [tenantServices, setTenantServices] = useState<Record<any, string>>();

  useEffect(() => {
    const getServicesAsync = async () => {
      const servicesresponse = await listServices();
      setTenantServices(servicesresponse);
    };
    getServicesAsync();
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (id && tenantServices) {
        const { agreement, ...rest } = await offersApi.get(id);
        form.setFieldsValue({
          ...rest,
          agreement: {
            ...agreement,
            services: agreement.services ? agreement.services : [tenantServices?.[0]],
          },
        });
        setCurrentChosenOfferType(agreement.offerType);
      }
    };
    getData();
  }, [form, id, tenantServices]);

  const onFinish = (values: OfferBase) => {
    async function postData(fieldsValue: OfferBase) {
      try {
        const { agreement, ...rest } = fieldsValue;
        const payload = {
          ...rest,
          agreement: {
            ...agreement,
            services: Array.isArray(agreement.services) ? agreement.services : [agreement.services],
          },
        };
        const response = id ? await offersApi.update(id, payload) : await offersApi.create(payload);
        if (response) {
          history.push("/");
        }
      } catch (err) {
        alert(`${err} Något gick fel!`);
      }
    }
    postData(values);
  };

  const validateMessages = {
    /* eslint-disable */
    required: "${label} är kravbelagd!",
    types: {
      number: "${label} är inte ett nummer!",
      email: "${label} är inte en giltig epost!",
    },
    /* eslint-enable */
  };

  const offerTypeChange = (offertype: OfferType) => {
    setCurrentChosenOfferType(offertype);
    form.setFieldsValue({
      agreement: {
        offerType: offertype,
        billingPeriod: undefined,
        budget: undefined,
        comment: undefined,
        contractPeriod: undefined,
        costCenter: undefined,
        cpm: undefined,
        endDate: undefined,
        evaluationPeriod: undefined,
        initialContractPeriod: undefined,
        paymentTerm: undefined,
        startDate: undefined,
        startCost: undefined,
        services: [tenantServices![offertype]],
      },
    });
  };

  function setEnabledStartDate(current: moment.Moment) {
    const myNewDate = new Date();
    return current && current < moment(myNewDate.setDate(myNewDate.getDate() + 5)).startOf("day");
  }

  function setEnabledEndDate(current: moment.Moment) {
    const myNewDate = new Date(form.getFieldValue(["agreement", "startDate"]));
    return current && current < moment(myNewDate.setDate(myNewDate.getDate() + 5)).startOf("day");
  }

  return (
    <MainLayout>
      <>
        <div className="pageWrapper">
          <div className="headerWrapper">
            <h2>{title}</h2>
            <Button className="headerButton">
              <Link to={`/`}>Avbryt</Link>
            </Button>
          </div>

          <Form
            className={classes.form}
            name="BIFirm"
            form={form}
            layout={"vertical"}
            onFinish={onFinish}
            validateMessages={validateMessages}>
            <div className="ant-form-content ant-form-topLeft">
              <FormItem
                label="Företagets namn"
                name={["client", "name"]}
                rules={[{ required: true, type: "string" }]}>
                <Input />
              </FormItem>

              <FormItem
                name={["client", "organizationNumber"]}
                label="Organisationsnummer"
                rules={[{ required: true, type: "string" }]}>
                <Input />
              </FormItem>

              <FormItem label="Webbplats" name={["client", "website"]} rules={[{ required: true }]}>
                <Input />
              </FormItem>

              <FormItem
                label="Företags tel:nr"
                name={["client", "phone"]}
                rules={[{ required: true, type: "string" }]}>
                <Input type="tel" />
              </FormItem>

              <FormItem label="Adress" name={["client", "address", "street"]} rules={[{ required: true }]}>
                <Input />
              </FormItem>

              <FormItem label="Stad" name={["client", "address", "city"]} rules={[{ required: true }]}>
                <Input />
              </FormItem>

              <FormItem
                label="Postnummer"
                name={["client", "address", "zipCode"]}
                rules={[{ required: true, type: "string" }]}>
                <Input />
              </FormItem>

              <FormItem
                label="Kontaktperson namn"
                name={["contactPerson", "name"]}
                rules={[{ required: true, type: "string" }]}>
                <Input />
              </FormItem>

              <FormItem
                label="Kontaktperson Epost"
                name={["contactPerson", "email"]}
                rules={[{ required: true, type: "email" }]}>
                <Input type="email" />
              </FormItem>

              <FormItem
                label="Kontaktperson Mobil"
                name={["contactPerson", "phone"]}
                rules={[{ required: true, type: "string" }]}>
                <Input type="tel" />
              </FormItem>

              <FormItem label="Typ av media" name={["agreement", "offerType"]} rules={[{ required: true }]}>
                <Select placeholder="Välj typ av media" onChange={offerTypeChange}>
                  {renderEnumOptions(offerTypeOptions)}
                </Select>
              </FormItem>

              <FormItem
                label="Namn på artikel i offert"
                name={["agreement", "services"]}
                rules={[{ required: true }]}>
                <TextArea />
              </FormItem>
            </div>

            <div className="ant-form-content">
              <FormItem
                label="Startdatum"
                name={["agreement", "startDate"]}
                rules={[{ type: "object", required: currentChosenOfferType !== OfferType.OneTime }]}>
                <DatePicker
                  disabledDate={setEnabledStartDate}
                  disabled={currentChosenOfferType === OfferType.OneTime}
                  className="date"
                />
              </FormItem>

              <FormItem
                label="Slutdatum"
                name={["agreement", "endDate"]}
                rules={[{ type: "object", required: currentChosenOfferType === OfferType.Scheduled }]}>
                <DatePicker
                  disabledDate={setEnabledEndDate}
                  disabled={currentChosenOfferType !== OfferType.Scheduled}
                  className="date"
                />
              </FormItem>

              <FormItem
                label="Avtalslängd period 1"
                name={["agreement", "initialContractPeriod"]}
                rules={[
                  {
                    required:
                      currentChosenOfferType === OfferType.Ongoing ||
                      currentChosenOfferType === OfferType.Individual,
                  },
                ]}>
                <Select
                  placeholder="Välj avtalslängd period 1"
                  disabled={
                    currentChosenOfferType !== OfferType.Ongoing &&
                    currentChosenOfferType !== OfferType.Individual
                  }>
                  {renderEnumOptions(contractPeriod)}
                </Select>
              </FormItem>

              <FormItem
                label="Avtalslängd fortsättningsvis"
                name={["agreement", "contractPeriod"]}
                rules={[
                  {
                    required:
                      currentChosenOfferType === OfferType.Ongoing ||
                      currentChosenOfferType === OfferType.Individual,
                  },
                ]}>
                <Select
                  placeholder="Välj avtalslängd fortsättningsvis"
                  disabled={
                    currentChosenOfferType !== OfferType.Ongoing &&
                    currentChosenOfferType !== OfferType.Individual
                  }>
                  {renderEnumOptions(contractPeriod)}
                </Select>
              </FormItem>

              <FormItem
                label="Utvärderingsperiod"
                name={["agreement", "evaluationPeriod"]}
                dependencies={["initialContractPeriod"]}
                hasFeedback
                rules={[
                  {
                    required: currentChosenOfferType === OfferType.Ongoing,
                    message: "måste väljas",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue(["agreement", "initialContractPeriod"]) > value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Utvärderingsperiod måste vara kortare än Avtalslängd period 1")
                      );
                    },
                  }),
                ]}>
                <Select
                  placeholder="Välj periods alternativ"
                  disabled={currentChosenOfferType !== OfferType.Ongoing}>
                  {renderEnumOptions(evaluationPeriod)}
                </Select>
              </FormItem>

              <FormItem
                label="Faktureringsintervall"
                name={["agreement", "billingPeriod"]}
                rules={[
                  {
                    required:
                      currentChosenOfferType !== OfferType.OneTime &&
                      currentChosenOfferType !== OfferType.Scheduled,
                  },
                ]}>
                <Select
                  placeholder="Faktureringsintervall"
                  disabled={
                    currentChosenOfferType === OfferType.OneTime ||
                    currentChosenOfferType === OfferType.Scheduled
                  }>
                  {currentChosenOfferType === OfferType.Ongoing
                    ? renderEnumOptions(billingPeriodOngoingOptions)
                    : renderEnumOptions(billingPeriodIndividualOptions)}
                </Select>
              </FormItem>

              <FormItem
                label="Betalningsvilkor"
                name={["agreement", "paymentTerm"]}
                rules={[{ required: true }]}>
                <Select placeholder="Välj betalnings vilkor">{renderEnumOptions(paymentTerm)}</Select>
              </FormItem>

              <FormItem
                label="Kostnadsställe/PO-nummer"
                name={["agreement", "costCenter"]}
                rules={[{ required: true, type: "string" }]}>
                <Input />
              </FormItem>

              <FormItem
                name={["agreement", "startCost"]}
                label="Startkostnad"
                rules={[
                  {
                    required:
                      currentChosenOfferType === OfferType.Ongoing ||
                      currentChosenOfferType === OfferType.Individual,
                    type: "number",
                  },
                ]}>
                <InputNumber
                  disabled={
                    currentChosenOfferType !== OfferType.Ongoing &&
                    currentChosenOfferType !== OfferType.Individual
                  }
                  type="number"
                />
              </FormItem>

              <FormItem
                label={changeOfferBudgetLabel(currentChosenOfferType)}
                name={["agreement", "budget"]}
                rules={[{ required: true, type: "number" }]}>
                <InputNumber className="ant-input" type="number" />
              </FormItem>

              <FormItem
                label="CPM"
                name={["agreement", "cpm"]}
                rules={[{ required: currentChosenOfferType !== OfferType.OneTime, type: "number" }]}>
                <InputNumber
                  className="ant-input"
                  type="decimal"
                  disabled={currentChosenOfferType === OfferType.OneTime}
                />
              </FormItem>

              <FormItem
                label="Kommentar"
                name={["agreement", "comment"]}
                className="comment"
                rules={[{ required: false }]}>
                <TextArea />
              </FormItem>

              <FormItem name={["agreement", "exclusiveProviderClause"]} valuePropName="checked">
                <Checkbox className="avtalsText">
                  Avtalstext om att teckna avtal och arbeta med annan annonsleverantör med en liknande
                  annonstjänst
                </Checkbox>
              </FormItem>
              <FormItem className="formSubmitWrapper">
                <Button type="primary" htmlType="submit" className="formSubmitButton">
                  Spara offert
                </Button>
              </FormItem>
            </div>
          </Form>
        </div>
      </>
    </MainLayout>
  );
};
export default OfferForm;
